<template>
  <div>
    <div v-if="!isLoading">
      <!-- Statistik Kelas  -->
      <b-row>
        <!-- Jumlah Kelas -->
        <b-col>
          <b-card>
            <b-media no-body>
              <b-media-aside class="mr-2">
                <b-avatar size="48" variant="light-primary">
                  <i class="font-medium-5 ti ti-school" />
                </b-avatar>
              </b-media-aside>
              <b-media-body class="my-auto">
                <h4 class="font-weight-bolder mb-0">
                  Kelas
                </h4>
                <b-card-text class="font-small-3 mb-0">
                  {{ this.dashboardData.classroomCount }} Kelas Diampu
                </b-card-text>
              </b-media-body>
            </b-media>
          </b-card>
        </b-col>

        <!-- Jumlah Siswa -->
        <b-col>
          <b-card>
            <b-media no-body>
              <b-media-aside class="mr-2">
                <b-avatar size="48" variant="light-warning">
                  <i class="font-medium-5 ti ti-users" />
                </b-avatar>
              </b-media-aside>
              <b-media-body class="my-auto">
                <h4 class="font-weight-bolder mb-0">
                  Siswa
                </h4>
                <b-card-text class="font-small-3 mb-0">
                  {{ this.dashboardData.studentCount }} Siswa diajar
                </b-card-text>
              </b-media-body>
            </b-media>
          </b-card>
        </b-col>

        <!-- Jumlah Materi -->
        <b-col>
          <b-card>
            <b-media no-body>
              <b-media-aside class="mr-2">
                <b-avatar size="48" variant="light-success">
                  <i class="font-medium-5 ti ti-book-2" />
                </b-avatar>
              </b-media-aside>
              <b-media-body class="my-auto">
                <h4 class="font-weight-bolder mb-0">
                  Materi
                </h4>
                <b-card-text class="font-small-3 mb-0">
                  {{ this.dashboardData.materialCount }} Materi Dibuat
                </b-card-text>
              </b-media-body>
            </b-media>
          </b-card>
        </b-col>

        <!-- Jumlah Tugas -->
        <b-col>
          <b-card>
            <b-media no-body>
              <b-media-aside class="mr-2">
                <b-avatar size="48" variant="light-info">
                  <i class="font-medium-5 ti ti-clipboard-list" />
                </b-avatar>
              </b-media-aside>
              <b-media-body class="my-auto">
                <h4 class="font-weight-bolder mb-0">
                  Tugas
                </h4>
                <b-card-text class="font-small-3 mb-0">
                  {{ this.dashboardData.taskCount }} Tugas Diberikan
                </b-card-text>
              </b-media-body>
            </b-media>
          </b-card>
        </b-col>
      </b-row>

      <!-- News Berita -->
      <b-row class="mb-2">
        <b-col lg="12">
          <b-carousel
            v-if="newsImages.length > 0"
            id="carousel-caption"
            controls
            indicators
          >
            <b-carousel-slide
              v-for="item in newsImages"
              :key="item.id"
              :caption-html="
                `
                <span class='text-white'>
                  ${item.title}
                </span> </br>
                <a href='/teacher/news/${item.id}' class='mt-1 btn btn-primary' target='_self'>Detail Berita</a>`
              "
              class="card-img-overlay news-slide-content"
              :img-src="item.image"
            />
          </b-carousel>

          <div v-if="newsImages.length === 0" class="text-center mt-4">
            <b-spinner variant="primary" class="mr-1" />
            <p class="mt-1">
              Memuat Berita
            </p>
          </div>
        </b-col>
      </b-row>
    </div>

    <div v-if="isLoading" class="d-flex justify-content-center vertical-center">
      <div class="text-center mt-4">
        <b-spinner variant="primary" class="mr-1" />
        <p class="mt-1">
          Memuat Dashboard
        </p>
      </div>
    </div>
  </div>
</template>

<script>
// *Components
import {
  BRow,
  BCol,
  BCarousel,
  BCarouselSlide,
  BSpinner,
  BCard,
  BMedia,
  BMediaAside,
  BMediaBody,
  BAvatar,
  BCardText,
} from 'bootstrap-vue'

// *Utils
import client from '@/libs/http/axios-config'

export default {
  components: {
    BRow,
    BCol,
    BCarousel,
    BCarouselSlide,
    BSpinner,
    BCard,
    BMedia,
    BMediaAside,
    BMediaBody,
    BAvatar,
    BCardText,
  },
  data() {
    return {
      isLoading: true,
      dashboardData: {},
      newsImages: [],
    }
  },
  async mounted() {
    await client
      .get('/teachers/dashboard')
      .then((response) => {
        this.dashboardData = response.data.data
      })
      .catch((error) => {
        console.error(error)
      })

    await client
      .get('/news?status=published')
      .then((response) => {
        this.newsImages = response.data.data

        this.isLoading = false
      })
      .catch((error) => {
        console.error(error)
      })
  },
}
</script>

<style lang="scss">
.card-img-overlay {
  padding: 0 !important;
}

.news-slide-content {
  border-radius: 0.428rem !important;

  img {
    height: 350px;
    border-radius: 0.428rem !important;
    object-fit: cover;
  }
}
</style>
